import React, { useState } from "react";
import { Link } from "react-router-dom";
import cardImg from "../assets/images/card-img.png";

import chromeIcon from "../assets/images/icons/chrome.png";
import recordIcon from "../assets/images/icons/Record.png";
import waveIcon from "../assets/images/icons/Waveform.png";
import commandIcon from "../assets/images/icons/Command.png";
import wandIcon from "../assets/images/icons/MagicWand.png";
import quote from "../assets/images/icons/quote.png";
import avatar from "../assets/images/icons/avatar.png";

function Home() {
  // State to manage the user's name input
  const [name, setName] = useState("");

  // State to control video visibility in the modal
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  // Function to handle name submission, updates name state
  const handleNameSubmit = (name) => {
    setName(name);
  };

  // Function to toggle the visibility of the video in the modal
  const handleToggleVideo = () => {
    setIsVideoVisible(!isVideoVisible);
  };

  return (
    <div>
      {/* Main wrapper div */}
      <div className="wrapper ">
        {/* Main content container */}
        <div className="flex flex-col app-container py-4 px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto pt-20">
          {" "}
          {/* Added padding-top */}
          {/* Hero section */}
          <main className="hero-container flex flex-col gap-4 justify-center items-center rounded-3xl py-8 px-2 ">
            {/* Main headline */}
            <h1 className="font-bold text-4xl lg:text-7xl text-neutral-800 text-center mt-4 lg:leading-16">
              Your{" "}
              <span className="bg-gradient-to-br from-violet-500 via-violet-400 to-pink-400 inline-block text-transparent bg-clip-text">
                Autonomous
              </span>{" "}
              <br /> Browser{" "}
              <span className="bg-gradient-to-br from-violet-500 via-violet-400 to-pink-400 inline-block text-transparent bg-clip-text">
                Assistant
              </span>
            </h1>
            <p className=" font-normal text-neutral-600 text-lg">
              The Simplest Way to Bring AI into Your Workflow
            </p>
            <a href="https://www.google.com" target="_blank">
              <button className="bg-gradient-to-br  from-violet-600  to-violet-800 px-4 py-3 rounded-xl nav-button text-white flex gap-1 items-center shadow-lg shadow-purple-300">
                <img src={chromeIcon} alt="chrome icon" className="h-6" />
                <sp className="me-2 text-lg">Install Our Free Extension</sp>
              </button>
            </a>

            {/* Video section */}
            <div className="video-section w-full md:w-4/5 lg:w-4/5 xl:w-5/6 aspect-video px-2 my-5">
              <div className="relative md:p-2 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl w-full h-full">
                <div className="video-container relative bg-white p-0 rounded-2xl h-full">
                  <iframe
                    className="w-full h-full rounded-2xl"
                    src="https://www.youtube.com/embed/6LOtqprIeGQ?si=rwZwigr-CjATPgyr"
                    title="some video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </main>
          {/* Card section */}
          <section className=" flex flex-col gap-3 items-center lg:py-12 py-6">
            <h3 className="text-neutral-850 text-3xl text-center">
              Effortless AI Assistance, Seamlessly Integrated
            </h3>
            <p className=" font-normal text-neutral-600 text-center">
              Smart tools to simplify your workflow—without interruptions.
            </p>
            <div className="cards w-11/12 md:w-full grid md:grid-cols-2 grid-cols-1 gap-5 lg:gap-8 my-4 ">
              {/* First card */}
              <div className="p-1 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl h-fit">
                <div className="card-small p-6 lg:p-8 bg-white flex flex-col gap-4 rounded-3xl h-fit">
                  <span className="w-fit">
                    <img src={waveIcon} alt="" className="h-8" />
                  </span>
                  <h1 className="text-xl text-neutral-800 w-full lg:w-2/3">
                    Voice Assistant
                  </h1>
                  <p className="text-md text-neutral-500">
                    Handle tasks with simple voice commands—email, schedule,
                    search—all within your browser.
                  </p>
                  <span className="border border-neutral-200 rounded-xl h-48 bg-gray-50"></span>
                </div>
              </div>
              {/* Second card */}
              <div className="p-1 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl h-fit">
                <div className="card-small p-6 lg:p-8 bg-white flex flex-col gap-4 rounded-3xl h-fit">
                  <span className="w-fit">
                    <img src={commandIcon} alt="" className="h-8" />
                  </span>
                  <h1 className="text-xl text-neutral-800 w-full lg:w-2/3">
                    In-Browser Sidebar
                  </h1>
                  <p className="text-md text-neutral-500">
                    Access Mera on every page to manage tasks and chat without
                    disrupting your workflow.
                  </p>
                  <span className="border border-neutral-200 rounded-xl h-48 bg-gray-50"></span>
                </div>
              </div>
              {/* Third card */}
              <div className="p-1 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl h-fit">
                <div className="card-small p-6 lg:p-8 bg-white flex flex-col gap-4 rounded-3xl h-fit">
                  <span className="w-fit">
                    <img src={wandIcon} alt="" className="h-8" />
                  </span>
                  <h1 className="text-xl text-neutral-800 w-full lg:w-2/3">
                    Quick Actions
                  </h1>
                  <p className="text-md text-neutral-500">
                    Instantly access smart tools like CleanUp, Translate, and
                    Summarize with a simple text highlight.
                  </p>
                  <span className="border border-neutral-200 rounded-xl h-48 bg-gray-50"></span>
                </div>
              </div>
              {/* Fourth card */}
              <div className="p-1 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl h-fit">
                <div className="card-small p-6 lg:p-8 bg-white flex flex-col gap-4 rounded-3xl h-fit">
                  <span className="w-fit">
                    <img src={recordIcon} alt="" className="h-8" />
                  </span>
                  <h1 className="text-xl text-neutral-800 w-full lg:w-2/3">
                    Voice-to-Text
                  </h1>
                  <p className="text-md text-neutral-500">
                    Speak to type anywhere online, turning your voice into
                    clean, ready-to-deliver text.
                  </p>
                  <span className="border border-neutral-200 rounded-xl h-48 bg-gray-50"></span>
                </div>
              </div>
            </div>
            <p className=" font-normal text-neutral-600 text-center">
              and much more!
            </p>
          </section>
          <span className="p-1 bg-gradient-to-br from-purple-300 via-blue-300 to-pink-300 rounded-3xl mx-4 mb-6">
            <section className="testimonial flex flex-col py-8 px-4 lg:py-10 lg:px-12  bg-violet-50 rounded-2xl items-center gap-6 lg:gap-8">
              <div className="flex gap-2">
                <span className=" w-fit">
                  <img
                    src={quote}
                    alt="quote icon"
                    className=" max-w-fit h-10"
                  />
                </span>
                <p className="text-xl text-neutral-800 px-4 lg:px-12 text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veni nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea commodo.
                </p>
              </div>
              <div className="flex gap-3 items-center">
                <span className=" w-fit">
                  <img
                    src={avatar}
                    alt="peace avatar"
                    className=" max-w-fit h-12"
                  />
                </span>
                <div>
                  <h3 className="text-xl">Beta User</h3>
                  <p className="text-lg text-neutral-800 ">
                    Chief Beta Tester at Testing Products Inc
                  </p>
                </div>
              </div>
            </section>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Home;
