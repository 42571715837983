import React from "react";
import meraLogo from "../assets/images/meralogo.png";
import { Link } from "react-router-dom";
import slackIcon from "../assets/images/icons/slack.png";
import twitterIcon from "../assets/images/icons/twitter.png";
import linkedinIcon from "../assets/images/icons/linkedin.png";

function Footer() {
  return (
    <div className="bg-neutral-100">
      <div className=" py-8 lg:py-10  px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-8 lg:gap-0 text-slate-900">
          {/* Footer Left Column */}
          {/* Footer Right Column */}

          <div className="flex flex-col  gap-4 text-center lg:text-right">
            <span className="flex justify-center lg:justify-start">
              <img src={meraLogo} alt="mera logo" className="h-7 w-auto" />
            </span>
            <p className=" text-center text-neutral-500 lg:text-left text-sm">
              © 2024 Mera Labs Technologies Inc.
            </p>
          </div>

          <div className="flex flex-col  gap-4  text-center">
            <span className="flex gap-3 justify-center lg:justify-end">
              <Link
                to="https://join.slack.com/t/meralabs/shared_invite/zt-2uehy3p1t-VtZZHDJGHWZASAiEa4vuTg"
                className=""
                target="_blank"
              >
                <img src={slackIcon} alt="mera logo" className="h-6 w-auto" />
              </Link>
              <Link
                to="https://linkedin.com/company/mera-labs"
                className=""
                target="_blank"
              >
                <img
                  src={linkedinIcon}
                  alt="mera logo"
                  className="h-6 w-auto"
                />
              </Link>
              <Link to="https://x.com/MeraLabsHQ" className="" target="_blank">
                <i className="bi bi-twitter-x"></i>
              </Link>
            </span>
            <span className="flex gap-2">
              <Link
                to="/termsofservice"
                target="_blank"
                className=" hover:underline text-neutral-600 hover:text-neutral-900 text-sm"
              >
                Terms of Service
              </Link>
              <span>|</span>
              <Link
                to="/privacypolicy"
                target="_blank"
                className=" hover:underline text-neutral-600 hover:text-neutral-900 text-sm"
              >
                Privacy Policy
              </Link>
            </span>
          </div>
        </div>
        {/* <hr className="mt-3" /> */}
      </div>
    </div>
  );
}

export default Footer;
