import React, { useState } from "react";
import logo from "../assets/images/meralogo.png";
import { Link } from "react-router-dom";
import chromeIcon from "../assets/images/icons/chrome.png";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar bg-none fixed top-0 left-0 w-full z-40 bg-white">
      {" "}
      {/* Added bg-white */}
      <header className="h-fit flex w-100 justify-between items-center px-2 lg:px-12 max-w-full xl:max-w-7xl mx-auto py-4 lg:py-4">
        {" "}
        {/* Increased padding-top */}
        <Link to="/" className="">
          <img src={logo} alt="Logo" className="logo h-9 w-auto" />
        </Link>
        <button className="md:hidden text-slate-900" onClick={toggleMenu}>
          <i className={`bi ${menuOpen ? "bi-x" : "bi-list"} text-2xl`}></i>
        </button>
        <div
          className={`lg:flex ${
            menuOpen ? "flex" : "hidden"
          } flex-col md:flex-row gap-6 md:gap-8 items-center absolute md:static top-16 left-0 shadow-lg md:shadow-none w-full lg:justify-between lg:w-3/5 md:w-auto bg-white md:bg-transparent md:top-auto md:left-auto px-4 md:px-0 py-6 md:py-0`}
        >
          <div className="flex flex-col items-center md:flex-row gap-6 text-slate-900">
            <Link
              to="https://www.google.com"
              className="text-neutral-600 hover:text-neutral-900"
            >
              Docs
            </Link>
            <Link
              to="https://www.google.com"
              className="text-neutral-600 hover:text-neutral-900"
            >
              Blog
            </Link>
            <Link
              to="/pricing"
              className="text-neutral-600 hover:text-neutral-900"
            >
              Pricing
            </Link>
            <Link
              to="https://www.google.com"
              className="text-neutral-600 hover:text-neutral-900"
            >
              Roadmap
            </Link>
          </div>
          <a href="https://www.google.com" target="_blank">
            <button className="bg-gradient-to-br  from-violet-600  to-violet-800 px-3 py-2 rounded-xl nav-button text-white flex gap-1 items-center shadow-lg shadow-purple-300">
              <img src={chromeIcon} alt="chrome icon" className="h-6" />
              <span className="me-2">Download</span>
            </button>
          </a>
        </div>
      </header>
    </div>
  );
}

export default Header;
