import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import Terms from "./Pages/TermsOfService";
import Privacy from "./Pages/PrivacyPolicy";

import Header from "./components/Header"; // Import your Header component
import Footer from "./components/Footer";

// Create a layout component that includes the Header
const Layout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/termsofservice",
        element: <Terms />,
      },
      {
        path: "/privacypolicy",
        element: <Privacy />,
      },
      // {
      //   path: "/blog01",
      //   element: <Blog01 />,
      // },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
